<template>
  <a
    v-if="shouldDisplay"
    :href="imageLink"
    class="LeaderboardAd"
  >
    <img
      :src="imageSrc"
      class="column is-record-ad is-hidden-mobile has-text-centered"
      alt="advertisemnt"
    >
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      ads: (state) => state.advertisements.record_ads,
    }),
    shouldDisplay() {
      return !!this.ads?.length;
    },
    imageSrc() {
      return Array.from(this.ads)[0].src;
    },
    imageLink() {
      return Array.from(this.ads)[0].link;
    },
  },
};
</script>
